<template>
  <b-card title="Atendentes">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Pesquisar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template #cell(media_dias)="data">
        <b-badge :variant="badgeVariantPerfil(data.item.status_os.nome)">
          {{ data.item.status_os.nome }}
        </b-badge>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from '../../../../axios-auth'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'Nome',
          field: 'name',
        },
        {
          label: 'Total',
          field: 'quantidade_total',
        },
        {
          label: 'AG',
          field: 'agendar',
        },
        {
          label: 'AT',
          field: 'em_atendimento',
        },
        {
          label: 'AA',
          field: 'aguardando_aprovacao',
        },
        {
          label: 'ER',
          field: 'em_reparo',
        },
        {
          label: 'RC',
          field: 'reparo_concluido',
        },
        {
          label: 'RE',
          field: 'reparo_entregue',
        },
        {
          label: 'T',
          field: 'troca',
        },
        {
          label: 'N',
          field: 'negado',
        },
        {
          label: 'C',
          field: 'cancelado',
        },
        {
          label: 'CMG',
          field: 'cancelado',
        },
        {
          label: 'CMG',
          field: 'cancelado',
        },
        {
          label: 'TAT',
          field: 'media_dias_aberto',
        },
        {
          label: 'PT',
          field: 'cancelado',
        },
        {
          label: 'REC',
          field: 'cancelado',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    await axios.get(`/api/empresas/totalizadores/atendentes/${empresaID}`)
      .then(res => {
        const i = []
        res.data.dados.map(item => {
          item.media_dias_aberto = this.formatDias(item.media_dias_aberto)
          i.push(item)
        })
        this.rows = i
      })
  },
  methods: {
    formatDias(dias) {
      const num = parseFloat(dias)
      const fixedNum = num.toFixed(0)
      return fixedNum
    },
  },
}
</script>
